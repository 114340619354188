import gql from "graphql-tag";
import { KLARNA_PAYMENT_SESSION_ATTRIBUTES, ORDER_ATTRIBUTES } from "../fragments";
import { KlarnaPaymentSession } from "Types/klarnaTypes";
import { Order, WebPaymentResponse } from "Types";

export namespace KlarnaMutations {
    export type CreateKlarnaPaymentSession = {
        createKlarnaPaymentSession: KlarnaPaymentSession;
    };
    export type AddKlarnaWebOrder = {
        addKlarnaWebOrder: {
            order: Order;
            webPaymentResponse: WebPaymentResponse;
        };
    };
}

export const CREATE_KLARNA_PAYMENT_SESSION = gql`
    mutation createKlarnaPaymentSession($webOrderInput: WebOrderDTOInput) {
        createKlarnaPaymentSession(webOrderInput: $webOrderInput) {
		 ... on KlarnaPaymentSessionResponseDTO {
                    ...klarnaPaymentSessionResults
                }
        }
    }
    ${KLARNA_PAYMENT_SESSION_ATTRIBUTES}
`;

export const ADD_KLARNA_WEB_ORDER = gql`
    mutation addKlarnaWebOrder($webOrderInput: WebOrderDTOInput) {
        addKlarnaWebOrder(webOrderInput: $webOrderInput) {
            webPaymentResponse {
                operationSuccess
                errorText
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;