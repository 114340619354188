
import gql from "graphql-tag";



export const KLARNA_PAYMENT_SESSION_ATTRIBUTES = gql`
    fragment klarnaPaymentSessionResults on KlarnaPaymentSessionResponseDTO {
		clientToken
		paymentMethodCategories {
			assetUrls {
				descriptive
				standard
			}
			identifier
			name
		}
		sessionId
	}
	`;
