import gql from "graphql-tag";

export const SAVE_NEW_CAMPAIGN = gql`
    mutation upsertCampaign($companyId: String!, $newCampaign: CampaignInput, $userSearchDTO: CampaignUserSearchDTOInput) {
        upsertCampaign(companyId: $companyId, newCampaign: $newCampaign, userSearchDTO: $userSearchDTO)
    }
`;

export const END_CAMPAIGN_EARLY = gql`
    mutation finishCampaignEarly($companyId: String!, $campaignId: String!, $removePromotion: Boolean!) {
        finishCampaignEarly(companyId: $companyId, campaignId: $campaignId, removePromotion: $removePromotion)
    }
`;
