import gql from "graphql-tag";
import { ORDER_ATTRIBUTES } from "../fragments";
import { Order, WebPaymentResponse } from "Types/orderTypes";

export namespace OrderMutations {
    export type CreateWebFreeAmountRefund = {
        createWebFreeAmountRefund: {
            order: Order;
            webPaymentResponse: WebPaymentResponse;
        };
    };
    // Maybe want tor rename this to match the mutation name later.. "createWebRefund"
    export type RefundOnlineOrder = {
        createWebRefund: {
            order: Order;
            webPaymentResponse: WebPaymentResponse;
        };
    };
}

export const INVOICE_IS_HANDLED = gql`
    mutation invoiceIsHandled($orderId: String) {
        invoiceIsHandled(orderId: $orderId)
    }
`;

export const REFUND_ONLINE_ORDER = gql`
    mutation createWebRefund($webOrderInput: WebOrderDTOInput) {
        createWebRefund(webOrderInput: $webOrderInput) {
            order {
                ... on Order {
                    ...orderResults
                }
            }
            webPaymentResponse {
                operationSuccess
                errorText
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const CANCEL_CATERING_ORDER = gql`
    mutation cancelCateringOrder($orderId: String) {
        cancelCateringOrder(orderId: $orderId)
    }
`;
export const CANCEL_ONLINE_ORDER = gql`
    mutation cancelOnlineOrder($kitchenOrderId: String) {
        cancelOnlineOrder(kitchenOrderId: $kitchenOrderId) {
            id
        }
    }
`;

export const UPDATE_ONLINE_ORDER_STATUS = gql`
    mutation updateOnlineOrderStatus(
        $orderId: String
        $kitchenOrderId: String
        $orderStatus: String
        $pickupTime: String
    ) {
        updateOnlineOrderStatus(
            orderId: $orderId
            kitchenOrderId: $kitchenOrderId
            orderStatus: $orderStatus
            pickupTime: $pickupTime
        ) {
            updatedAt
            id
            eatingOption
            tableName
            discountName
            puckNo
            online
            onlineOrderStatus
            orderId
            orderNo
            orderType
            status
            pickupTime
            comment
            shopId
            takeAway
            homeDelivery
            createdAt
            driveThrough
            postponePayment
            paymentStatus
            thirdPartyDeliveryType
            thirdPartyCode
            arriveAtCustomerTime
            courierPickupTime
            cateringConfirmationDate
            contactInformation {
                name
                lastName
                phoneNumber
                addressLine
                postCode
                doorCode
                city
                floorNumber
            }
            kitchenOrderProducts {
                name
                comment
                hash
                quantity
                price
                refProductId
                refBundleProductId
                refProductCategoryId
                menuCategoryId
                addons {
                    name
                    quantity
                    price
                }
                modifications {
                    sizes {
                        name
                        addonPrice
                    }
                    flavours {
                        name
                        addonPrice
                    }
                }
                selectedBundleProductItems {
                    refProductId
                    bundleProductCategoryId
                    name
                    hash
                    kdsUnitDisplayName
                    refProductCategoryId
                    addons {
                        name
                        quantity
                        price
                    }
                    modifications {
                        sizes {
                            name
                            addonPrice
                        }
                        flavours {
                            name
                            addonPrice
                        }
                    }
                }
            }
            webOrderType
        }
    }
`;

export const USER_CANCELLED_CARD_PAYMENT = gql`
    mutation userCancelledCardPayment($orderId: String!) {
        userCancelledCardPayment(orderId: $orderId)
    }
`;

export const CREATE_RECEIPT_COPY = gql`
    mutation createReceiptCopy($orderId: String, $posId: String) {
        createReceiptCopy(orderId: $orderId, posId: $posId) {
            terminalResponse {
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const CANCEL_POS_ORDER = gql`
    mutation cancelPosOrder($terminalId: String) {
        cancelTransaction(terminalId: $terminalId)
    }
`;

export const KILL_TRANSACTION = gql`
    mutation killTransaction($terminalId: String, $posId: String, $shopId: String) {
        killTransaction(terminalId: $terminalId, posId: $posId, shopId: $shopId) {
            operationSuccess
        }
    }
`;

export const ADD_WEB_ORDER = gql`
    mutation addWebOrder($webOrderInput: WebOrderDTOInput) {
        addWebOrder(webOrderInput: $webOrderInput) {
            webPaymentResponse {
                operationSuccess
                errorText
                qrCode
                token
                redirectUrl
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const CREATE_AND_PAY_POS_ORDER_ON_WEB = gql`
    mutation createAndPayPosOrderOnWebQuery($posOrderInput: PosOrderDTOInput) {
        createAndPayPosOrderOnWeb(posOrderInput: $posOrderInput) {
            webPaymentResponse {
                operationSuccess
                errorText
                token
                qrCode
                redirectUrl
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const EXTEND_POSTPONED_PAYMENT_ORDER = gql`
    mutation extendPostponedOrder($orderId: String, $posOrderInput: PosOrderDTOInput) {
        extendPostponedOrder(orderId: $orderId, posOrderInput: $posOrderInput) {
            terminalResponse {
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const PAY_POSTPONED_PAYMENT_ORDER = gql`
    mutation payPostponedPaymentOrder($orderId: String, $posOrderInput: PosOrderDTOInput) {
        payPostponedPaymentOrder(orderId: $orderId, posOrderInput: $posOrderInput) {
            terminalResponse {
                cancelledByShop
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const CREATE_POS_POSTPONED_PAYMENT_ORDER = gql`
    mutation postponePaymentOrder($posOrderInput: PosOrderDTOInput, $orderWindow: OrderWindowInput) {
        postponePaymentOrder(posOrderInput: $posOrderInput, orderWindow: $orderWindow) {
            terminalResponse {
                cancelledByShop
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const LOG_EMPTY_POSORDER = gql`
    mutation logEmptyOrderInput($posId: String, $baxiCloudTerminalStateDTO: BaxiCloudTerminalStateDTOInput) {
        logEmptyOrderInput(posId: $posId, baxiCloudTerminalStateDTO: $baxiCloudTerminalStateDTO)
    }
`;

export const LOG_CLOUD_BAXI_MESSAGE = gql`
    mutation logBaxiMessage($terminalId: String, $msg: String) {
        logBaxiMessage(terminalId: $terminalId, msg: $msg)
    }
`;

export const CREATE_POSTPONED_POS_CLOUD_PURCHASE_ORDER = gql`
    mutation savePaidAttemptCloudPostponedOrder(
        $orderId: String
        $posOrderInput: PosOrderDTOInput
        $paymentStatus: PaymentStatus
    ) {
        savePaidAttemptCloudPostponedOrder(
            orderId: $orderId
            posOrderInput: $posOrderInput
            paymentStatus: $paymentStatus
        ) {
            terminalResponse {
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
                cancelledByShop
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const CREATE_POS_CLOUD_PURCHASE_ORDER = gql`
    mutation purchaseCloudCardPosOrder($posOrderInput: PosOrderDTOInput) {
        purchaseCloudCardPosOrder(posOrderInput: $posOrderInput) {
            terminalResponse {
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
                cancelledByShop
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const CREATE_POS_PURCHASE_ORDER = gql`
    mutation purchasePosOrder($posOrderInput: PosOrderDTOInput) {
        purchasePosOrder(posOrderInput: $posOrderInput) {
            terminalResponse {
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
                cancelledByShop
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const EMPTY_CART = gql`
    mutation emptyCart {
        emptyCart @client
    }
`;

export const REFUND_CLOUD_POS_ORDER = gql`
    mutation refundCloudCardPosOrder($posOrderInput: PosOrderDTOInput) {
        refundCloudCardPosOrder(posOrderInput: $posOrderInput) {
            terminalResponse {
                operationSuccess
                displayText
                errorText
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const REFUND_POS_ORDER = gql`
    mutation refundPosOrder($posOrderInput: PosOrderDTOInput) {
        refundPosOrder(posOrderInput: $posOrderInput) {
            terminalResponse {
                operationSuccess
                displayText
                errorText
            }
            order {
                ... on Order {
                    ...orderResults
                }
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const RETRY_FAILED_ORDER = gql`
    mutation retryFailedOrder($posId: String, $orderId: String) {
        retryFailedOrder(posId: $posId, orderId: $orderId) {
            order {
                ... on Order {
                    ...orderResults
                }
            }
            terminalResponse {
                operationSuccess
                displayText
                errorText
                terminalId
                posId
                pinByPass
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const PROCESS_CARD = gql`
    mutation processCardPayment($orderId: String, $netaxeptTransactionId: String) {
        processCardPayment(orderId: $orderId, netaxeptTransactionId: $netaxeptTransactionId) {
            webPaymentResponse {
                operationSuccess
                errorText
                redirectUrl
            }
        }
    }
`;

export const CANCEL_SWISH_PAYMENT = gql`
    mutation cancelSwishPayment($orderId: String) {
        cancelSwishPayment(orderId: $orderId)
    }
`;

export const ACCEPT_CATERING_ORDER = gql`
    mutation acceptCateringOrder($orderId: String) {
        acceptCateringOrder(orderId: $orderId) {
            onlineOrderStatus
        }
    }
`;

export const DECLINE_CATERING_ORDER = gql`
    mutation declineCateringOrder($orderId: String) {
        declineCateringOrder(orderId: $orderId) {
            onlineOrderStatus
        }
    }
`;

export const RESEND_ORDER_CONFIRMATION_EMAIL = gql`
    mutation resendOrderConfirmationEmail($orderId: String!, $orderEmail: String!) {
        resendOrderConfirmationEmail(orderId: $orderId, orderEmail: $orderEmail)
    }
`;

export const RESEND_CATERING_ORDER_CONFIRMATION_EMAIL = gql`
    mutation resendCateringOrderConfirmationEmail($orderId: String!, $orderEmail: String!) {
        resendCateringOrderConfirmationEmail(orderId: $orderId, orderEmail: $orderEmail)
    }
`;

export const SET_CATERING_KITCHEN_ORDER_TO_DELIVERED = gql`
    mutation setCateringKitchenOrderToDeliveredMutation($orderId: String!) {
        setCateringKitchenOrderToDelivered(orderId: $orderId)
    }
`;

export const CREATE_BONG_FROM_ORDERS = gql`
    mutation createBongFromOrdersMutation($shopId: String!, $orderIds: [String]!, $splitType: String, $puckNo: String) {
        createBongFromOrders(shopId: $shopId, orderIds: $orderIds, splitType: $splitType, puckNo: $puckNo)
    }
`;

export const SET_ORDER_PAYMENT_STATUS = gql`
    mutation setAbortedPostponedOrder($orderId: String!) {
        setAbortedPostponedOrder(orderId: $orderId)
    }
`;

export const CREATE_WEB_FREE_AMOUNT_REFUND = gql`
    mutation createWebFreeAmountRefundMutation(
        $amount: Float
        $referenceOrderId: String!
        $vatRate: Float
        $comment: String
        $customerEmail: String
    ) {
        createWebFreeAmountRefund(
            amount: $amount
            referenceOrderId: $referenceOrderId
            vatRate: $vatRate
            comment: $comment
            customerEmail: $customerEmail
        ) {
            order {
                ... on Order {
                    ...orderResults
                }
            }
            webPaymentResponse {
                errorText
                operationSuccess
                transactionId
            }
        }
    }
    ${ORDER_ATTRIBUTES}
`;

export const SET_POS_RECEIPT_TYPE_ON_ORDER = gql`
    mutation setPOSReceiptTypeOnOrderMutation($orderId: String, $posReceiptType: POSReceiptType, $email: String) {
        setPOSReceiptTypeOnOrder(orderId: $orderId, posReceiptType: $posReceiptType, email: $email)
    }
`;

export const SET_REFUND_STATUS_FAILED = gql`
    mutation setRefundStatusFailed($orderId: String) {
        setRefundStatusFailed(orderId: $orderId)
    }
`;

export const CANCEL_KLARNA_WEB_ORDER = gql`
    mutation cancelKlarnaWebOrder($orderId: String) {
        cancelKlarnaWebOrder(orderId: $orderId)
    }
`;
