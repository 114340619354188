import gql from "graphql-tag";

export const GET_SHOP_IDS_BY_COMPANY_ID = gql`
    query getAllShopsByCompanyIds($companyIds: [String]) {
        getAllShopsByCompanyIds(companyIds: $companyIds) {
            value: id
            label: name
        }
    }
`;

export const GET_VALID_DISCOUNT_CODES = gql`
    query getValidDiscountCodes($companyId: String!) {
        getValidDiscountCodes(companyId: $companyId)
    }
`;

export const SEARCH_FOR_POTENTIAL_CUSTOMERS = gql`
    query potentialNumberOfEmailsForCampaign($userSearchDTO: CampaignUserSearchDTOInput) {
        potentialNumberOfEmailsForCampaign(userSearchDTO: $userSearchDTO) {
            companyId
            shopIds
            campaignType
            numberOfMonthsBack
            numberOfHistoricOrders
            fromDate
            toDate
            campaignStartDate
            campaignEndDate
            potentialUserCount {
                shopId
                numberOfPotentialUsers
            }
        }
    }
`;

export const GET_ALL_CAMPAIGNS_BY_COMPANY_ID = gql`
    query getAllCampaignsByCompanyIdAndStatus($companyId: String!, $status: [CampaignStatus]) {
        getAllCampaignsByCompanyIdAndStatus(companyId: $companyId, status: $status) {
            id
            name
            campaignType
            campaignCode
            discountRate
            startDate
            endDate
            status
            theme
            pricingType
            totalEarnedRevenue
            maxRedeemed
            isQoplaPromotion
            statistics {
                shopId
                totalCost
                numberRedeemedOrders
                numberTargetedUsers
                totalEarnings
            }
        }
    }
`;

export const COMPANY_HAS_CAMPAIGN_ONGOING = gql`
    query checkCompanyHasRunningCampaign($companyId: String!) {
        checkCompanyHasRunningCampaign(companyId: $companyId)
    }
`;

export const GET_CAMPAIGN_HTML = gql`
    query getCampaignTemplate($companyId: String!) {
        getCampaignTemplate(companyId: $companyId)
    }
`;

export const COMPANY_HAS_USED_PROMOTION = gql` 
    query checkCompanyHasUsedPromotion($companyId: String!) { 
        checkCompanyHasUsedPromotion(companyId: $companyId)
    }
`;